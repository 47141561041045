<template>
  <v-app>
    <TheADHeader />
    <TheADDrawer />
    <!-- contents  -->
    <v-main class="ad-main" :class="{ 'bg-white': isDashBoard }">
      <CommonTab />
      <div class="mb-4">
        <router-view></router-view>
      </div>
      <CommonFooter :class="{ 'dash-footer': isDashBoard }" />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import TheADHeader from '@/components/TheADHeader.vue';
import TheADDrawer from '@/components/TheADDrawer.vue';
import CommonTab from '@/components/common/CommonTab.vue';
import { CommonFooter } from '@packages/components';
import { useRouter } from 'vue-router';
const $router = useRouter();
const isDashBoard = computed(() => {
  if ($router.currentRoute.value.name === 'ADDashBoard') {
    return true;
  }
  return false;
});
</script>

<style lang="scss" scoped>
.ad-main {
  position: relative;
}
.ad-main :deep(.dash-footer) {
  width: 100%;
  border-top: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  .footer {
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
  }
}
</style>
