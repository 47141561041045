import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/icon/icon__home.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-3b44688e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "d-flex-center-between" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: "home",
  class: "home-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_slide_group_item = _resolveComponent("v-slide-group-item")!
  const _component_v_slide_group = _resolveComponent("v-slide-group")!

  return (_openBlock(), _createBlock(_component_v_slide_group, {
    modelValue: _ctx.currentTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
    "show-arrows": "",
    class: "common-tab"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab, index) => {
        return (_openBlock(), _createBlock(_component_v_slide_group_item, { key: index }, {
          default: _withCtx(({ isSelected, select }) => [
            _createElementVNode("div", {
              class: _normalizeClass(["curved-trapezoid", { 'bg-white active': isSelected }]),
              onClick: ($event: any) => (select(true))
            }, [
              _createElementVNode("div", _hoisted_2, [
                (index === 0)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["small-text cursor-pointer", { 'txt-royal': isSelected }])
                      }, _toDisplayString(tab.text), 3),
                      _createVNode(_component_v_btn, {
                        onClick: ($event: any) => (_ctx.clickClose(index)),
                        variant: "plain",
                        icon: "mdi-close",
                        size: "x-small",
                        class: "grey-text pa-0 ma-0 ml-2 d-flex",
                        density: "compact"
                      }, null, 8, ["onClick"])
                    ], 64))
              ])
            ], 10, _hoisted_1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}