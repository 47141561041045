<template>
  <v-app-bar app elevation="0" class="admin-header border-bottom">
    <div class="d-flex-center-between w100p px-5">
      <router-link to="/ad">
        <img
          class="d-block cursor-pointer"
          src="../assets/images/logo__ubsales.png"
          alt="client logo"
          width="94px"
          height="16px" />
      </router-link>
      <div class="d-flex-center">
        <v-btn v-for="menu in menuList" :key="menu.id" class="px-3" @click="moveADPage(menu)">{{ menu.text }}</v-btn>
        <div><TheAccount location="ad" /></div>
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheAccount from '@/components/TheAccount.vue';
import { useRouter } from 'vue-router';
interface MenuType {
  text: string;
  id: string;
  link: string;
  menu: string;
}

export default defineComponent({
  name: 'TheAdHeader',
  components: { TheAccount },
  setup() {
    const menuList: MenuType[] = [
      { text: '지출보고 관리', id: 'report', link: '/ad/docs/compset', menu: 'report' },
      { text: '기준정보 관리', id: 'standard', link: '/ad/info/cont', menu: 'info' },
      // { text: '공지 사항', id: 'notice', link: '', menu: '' },
      // { text: '설정', id: 'setting', link: '', menu: '' },
      { text: '공지 및 설정', id: 'noticeAndSetting', link: '/ad/notice/all', menu: 'notice' },
      { text: '시스템 문의 및 오류 접수', id: 'system', link: '', menu: '' },
    ];

    const $router = useRouter();
    const moveADPage = (menu: MenuType) => {
      if (menu.link) {
        $router.push(menu.link);
      } else {
        alert('준비중입니다.');
      }
    };
    return { menuList, moveADPage };
  },
});
</script>

<style lang="scss" scoped></style>
