<template>
  <v-slide-group v-model="currentTab" show-arrows class="common-tab">
    <v-slide-group-item v-for="(tab, index) in tabList" :key="index" v-slot="{ isSelected, select }">
      <div class="curved-trapezoid" :class="{ 'bg-white active': isSelected }" @click="select(true)">
        <div class="d-flex-center-between">
          <img v-if="index === 0" src="@/assets/images/icon/icon__home.png" alt="home" class="home-btn" />
          <template v-else>
            <div class="small-text cursor-pointer" :class="{ 'txt-royal': isSelected }">
              {{ tab.text }}
            </div>
            <v-btn
              @click="clickClose(index)"
              variant="plain"
              icon="mdi-close"
              size="x-small"
              class="grey-text pa-0 ma-0 ml-2 d-flex"
              density="compact" />
          </template>
        </div>
      </div>
    </v-slide-group-item>
  </v-slide-group>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface TabType {
  text: string;
  link: string;
}

export default defineComponent({
  name: 'CommonTab',
  setup() {
    const $router = useRouter();
    const currentTab = ref(0);
    const tabList = ref<TabType[]>([
      {
        text: 'Main',
        link: '/ad',
      },
    ]);

    function initTab() {
      if ($router.currentRoute.value.path !== '/ad') {
        let textLabel = String($router.currentRoute.value?.meta?.label || '');
        tabList.value.push({
          text: textLabel,
          link: $router.currentRoute.value.path,
        });
        currentTab.value = tabList.value.length - 1;
      }
    }

    initTab();

    // 탭 리스트 변경
    watch(
      () => $router.currentRoute.value.path,
      newPath => {
        // 이미 있으면 추가하지 않음
        const findIdx = tabList.value.findIndex(v => v.link === newPath);
        if (findIdx !== -1) {
          currentTab.value = findIdx;
        } else {
          let textLabel = String($router.currentRoute.value?.meta?.label || '');
          tabList.value.push({
            text: textLabel,
            link: newPath,
          });
          currentTab.value = tabList.value.length - 1;
        }
      }
    );

    // tab 클릭으로 라우터 변경
    watch(currentTab, (newVal: number) => {
      const current = tabList.value[newVal];
      if (current) {
        $router.push(current.link);
      }
    });

    const clickClose = (index: number) => {
      tabList.value.splice(index, 1);
      const tabLength = tabList.value.length;
      if (tabLength > 2) {
        currentTab.value = tabLength - 1;
      } else {
        currentTab.value = index - 1;
      }
    };
    return { currentTab, tabList, clickClose };
  },
});
</script>

<style scoped>
.common-tab {
  background: #f0eafa;
  padding: 10px 10px 0px;
}
.common-tab :deep(.v-slide-group__content) {
  justify-content: flex-start;
}
.curved-trapezoid {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 26px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  height: 36px;
}
.curved-trapezoid.active::before {
  content: '';
  height: 36px;
  width: 30px;
  bottom: 0px;
  display: block;
  position: absolute;
  right: 0px;
  transform: skew(18deg);
  background: #fff;
  border-top-right-radius: 10px;
}
.home-btn {
  width: 24px;
  height: 24px;
  z-index: 1;
}
</style>
