<template>
  <div class="text-left" :class="wrapClass">
    <div v-if="title" :class="[{ required: isRequired }, titleClass]">{{ title }}</div>
    <v-text-field
      v-model="localTextVal"
      class="custom-text-field"
      :class="[classname, { required: isRequired }]"
      :label="label"
      :variant="variant"
      :placeholder="placeholder"
      :density="density"
      :readonly="readonly"
      :disabled="disabled"
      :type="passwordType"
      :value="localTextVal"
      @keyup="handleInput"
      @keyup.enter="$emit('enter')"
      hide-details="auto"
      :append-inner-icon="appendIconVal"
      :prepend-inner-icon="prependInnerIcon"
      @click:append-inner="clickAppendInnerIcon">
      <template v-if="isPrependInnerSlot" v-slot:prepend-inner>
        <div>{{ prependText }}</div>
      </template>
      <template v-if="isAppendInnerSlot" v-slot:append-inner>
        <div>{{ appendText }}</div>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, watch } from 'vue';

type VariantType = 'outlined' | 'filled' | 'plain' | 'solo' | 'solo-filled' | undefined;
type DensityType = 'default' | 'comfortable' | 'compact' | null | undefined;

export default defineComponent({
  name: 'BaseTextField',
  props: {
    textVal: { type: [String, Number], default: undefined },
    density: { type: String as () => DensityType, default: 'compact' },
    label: { type: String, default: undefined },
    variant: {
      type: String as () => VariantType,
      default: 'outlined',
    },
    placeholder: { type: String, default: undefined },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'text' }, // password, commanum
    appendInnerIcon: { type: String, default: undefined },
    prependInnerIcon: { type: String, default: undefined },
    title: { type: String, default: undefined },
    isRequired: { type: Boolean, default: false },
    classname: { type: String, default: '' },
    wrapClass: { type: String, default: '' },
    titleClass: { type: String, default: '' },
    isPrependInnerSlot: { type: Boolean, default: false },
    isAppendInnerSlot: { type: Boolean, default: false },
    prependText: { type: String, default: '' },
    appendText: { type: String, default: '' },
  },
  emits: ['update:textVal', 'appendIconClick', 'enter'],
  setup(props, context) {
    // 부모로부터 받은 textVal 초기화
    const localTextVal = ref(props.textVal);

    watch(
      () => props.textVal,
      (newVal, oldVal) => {
        if (newVal !== oldVal) localTextVal.value = newVal;
      },
      { immediate: true }
    );

    if (props.textVal && props.type === 'commanum') {
      localTextVal.value = Number(props.textVal).toLocaleString();
    }

    //password 타입일 떄 설정
    const isPwdType = ref(props.type === 'password');
    const isPwdShow = ref(false);
    const passwordType = computed(() => (isPwdType.value ? (isPwdShow.value ? 'text' : 'password') : props.type));

    // appendIcon 설정
    const appendIconVal = computed(() => {
      if (props.appendInnerIcon) return props.appendInnerIcon;
      return isPwdType.value ? (isPwdShow.value ? 'mdi-eye' : 'mdi-eye-off') : undefined;
    });

    const clickAppendInnerIcon = () => {
      if (isPwdType.value) {
        isPwdShow.value = !isPwdShow.value;
      } else {
        context.emit('appendIconClick');
      }
    };

    const isValidCharacter = (event: KeyboardEvent) => {
      const key = event.key;
      // 기능 키들을 제외하고 문자, 숫자, 특수 문자 여부를 검사
      const functionalKeys = ['Control', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Shift', 'Alt', 'Meta'];
      if (functionalKeys.includes(key) || event.ctrlKey || event.altKey || event.metaKey) return false;
      return true;
    };

    const handleInput = ($event: KeyboardEvent) => {
      const inputElement = $event.target as HTMLInputElement;
      const value = inputElement.value;

      // 타입별 입력변경
      switch (props.type) {
        case 'commanum': {
          const numericValue = Number(value.replace(/[^0-9]/g, ''));
          localTextVal.value = numericValue === 0 ? undefined : numericValue.toLocaleString();
          break;
        }
        case 'tel':
          localTextVal.value = value.replace(/[^0-9-]/g, '');
          break;
        case 'dotnum': {
          localTextVal.value = value.replace(/[^0-9.]/g, '');
          break;
        }
        case 'onlynum':
          localTextVal.value = value.replace(/[^0-9]/g, '');
          break;
        default:
          localTextVal.value = value;
          break;
      }

      const isValidKey = isValidCharacter($event);
      if (!isValidKey) return;
      context.emit('update:textVal', localTextVal.value);
    };

    return {
      localTextVal,
      passwordType,
      appendIconVal,
      clickAppendInnerIcon,
      handleInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-text-field {
  min-width: 60px;
}
</style>
