import { MenuItemType } from '@/types/interface/menu';
import menuData from '@/assets/json/menu.json';

class MenuDataService {
  getMRList(): Promise<any> {
    return new Promise(resolve => {
      setTimeout(() => {
        const menuList: { mr: MenuItemType[] } = menuData;
        resolve(menuList['mr']);
      }, 10);
    });
  }
}

export default new MenuDataService();
