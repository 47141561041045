<template>
  <div class="d-flex-center">
    <CommonLogo />
    <ul class="pc-menu">
      <li v-for="item in localMenuList" :key="item.id">
        <div @click="togglePCMenu">{{ item.label }}</div>
      </li>
    </ul>
    <v-menu
      transition="slide-y-transition"
      :close-on-content-click="false"
      v-model="pcMenuPanel"
      no-click-animation
      persistent>
      <div class="pc-panel pb-10 px-12 d-flex" v-click-outside="clickOutside">
        <div v-for="(item, index) in localMenuList" :key="index" class="panel-column">
          <div class="panel-title mt-8 mb-2">{{ item.label }}</div>
          <div v-for="subitem in item.subgroup" :key="subitem.title">
            <div>
              <div class="small-grey-text mt-6 mb-1">{{ subitem.title }}</div>
              <div v-for="menu in subitem.list" :key="menu.id" class="menu-item">
                <div @click="clickMenu(menu)">{{ menu.label }}</div>
                <!-- todo: active시 class="active" -->
                <v-chip v-if="menu.link" class="ml-2" size="x-small" color="blue" variant="flat">1</v-chip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { MenuItemType } from '@/types/interface/menu';
import CommonLogo from '@/components/common/CommonLogo.vue';

export default defineComponent({
  name: 'ThePCNavPanel',
  props: {
    menuList: {
      type: Array as () => MenuItemType[],
      default: () => [],
    },
  },
  components: { CommonLogo },
  setup(props, context) {
    const localMenuList = computed(() => {
      return props.menuList;
    });

    const pcMenuPanel = ref(false);

    const togglePCMenu = () => {
      pcMenuPanel.value = !pcMenuPanel.value;
    };
    const clickMenu = (item: MenuItemType) => {
      context.emit('clickMenu', item);
      pcMenuPanel.value = false;
    };
    const clickOutside = (e: Event) => {
      let targetElement = e.target as Element | null;
      let attemptCnt = 0;
      const maxAttempts = 5;
      // 상위 요소를 찾아가면서 pc-menu 클래스가 있는지 확인
      while (targetElement) {
        if (targetElement.classList.contains('pc-menu')) return;
        attemptCnt++;
        targetElement = targetElement.parentElement;
        // 최대 시도 횟수를 초과하면 종료
        if (attemptCnt >= maxAttempts) {
          pcMenuPanel.value = false;
          return;
        }
      }
    };
    return {
      pcMenuPanel,
      localMenuList,
      clickMenu,
      togglePCMenu,
      clickOutside,
    };
  },
});
</script>

<style lang="scss" scoped>
.pc-menu {
  display: flex;
  align-items: center;
  margin-left: 42px;
  li {
    margin: 0px 24px;
    font-weight: 600;
    cursor: pointer;
  }
}
.pc-panel {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100vw;
  min-height: 200px;
  position: absolute;
  top: 65px;
  background: #fff;
}
.panel-column {
  width: 280px;
}
.panel-title {
  font-weight: 600;
  color: #111111;
}
.menu-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  .active {
    color: #13bd7e;
    text-decoration: underline;
  }
}
</style>
