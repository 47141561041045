import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cc796ed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pa-1 ad-drawer-submenu"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    width: 200,
    class: "pa-0 pt-2 ad-drawer",
    modelValue: _ctx.isDrawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDrawer) = $event)),
    "mobile-breakpoint": 0,
    permanent: ""
  }, {
    default: _withCtx(() => [
      (_ctx.menuList && _ctx.menuList.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.menuList, (menu) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: menu.id,
              class: "text-left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["ad-drawer-title mb-1", { active: _ctx.currentRouteInfo.cat === menu.id }])
                }, [
                  _createVNode(_component_v_icon, {
                    size: "small",
                    class: "mr-1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(menu.icon), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createTextVNode(" " + _toDisplayString(menu.text), 1)
                ], 2),
                (menu.child && menu.child.length > 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.child, (submenu, idx) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: idx,
                          class: _normalizeClass(["cursor-pointer", { 'txt-menuactive': submenu.link === _ctx.currentRouteInfo.path }]),
                          onClick: ($event: any) => (_ctx.moveADPage(submenu))
                        }, _toDisplayString(submenu.text), 11, _hoisted_2))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}