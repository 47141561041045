import axiosCall from '../axios';
import { useAuthStore } from '@/stores/auth-store';

class AuthService {
  async doLogin({ id, pwd }: { id: string; pwd: string }): Promise<any> {
    try {
      // init
      const $auth = useAuthStore();
      $auth.resetUserInfo();

      // params
      const params = {
        username: id,
        password: pwd,
        domain: $auth.dominId,
      };

      // request
      const res = await axiosCall.post('/biz/user/login', params);

      // success
      if (res?.data?.status === 200) {
        const setUserProfile = {
          userId: res.data.userId || id,
          username: res.data.username || id,
          token: res.data.token,
        };
        $auth.setUserInfo(setUserProfile);

        return res.data;
      }

      // fail
      throw new Error('로그인에 실패했습니다.');
    } catch (error: any) {
      if (error.response) return error.response;
      return {
        status: 500,
        message: error.message,
      };
    }
  }
  async doLogout(): Promise<any> {
    const $auth = useAuthStore();
    try {
      await axiosCall.post('/biz/user/logout', { id: $auth.user.userId });
    } catch (error: any) {
      if (error.response) return error.response;
      return {
        status: 500,
        message: error.message,
      };
    } finally {
      $auth.resetUserInfo();
    }
  }
  async doRefreshToken(): Promise<any> {
    const $auth = useAuthStore();
    try {
      const res = await axiosCall.post('/biz/auth/authenticate', { id: $auth.user.userId });
      //success
      if (res?.data?.status === 200) {
        const setUserProfile = {
          userId: res.data.userId || 'admin', // 임시..
          username: res.data.username || 'TODO: 임시',
          token: res.data.token,
        };
        $auth.setUserInfo(setUserProfile);
        return res.data;
      }
    } catch (error: any) {
      $auth.resetUserInfo();
      if (error.response) return error.response;
      return {
        status: 500,
        message: error.message,
      };
    }
  }
  async getMyInfo(): Promise<any> {
    const $auth = useAuthStore();
    try {
      const res = await axiosCall.get(`/biz/user/myInfo`);
      if (res.status === 200) {
        const { data } = res.data;
        $auth.setMyInfo(data);
        return res;
      }
      throw new Error('내 정보 조회에 실패했습니다.');
    } catch (error: any) {
      $auth.resetMyInfo();
      if (error.response) return error.response;
      return {
        status: 500,
        message: error.message,
      };
    }
  }
}

export default new AuthService();
