import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-left", _ctx.wrapClass])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{ required: _ctx.isRequired }, _ctx.titleClass])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.localTextVal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localTextVal) = $event)),
      class: _normalizeClass(["custom-text-field", [_ctx.classname, { required: _ctx.isRequired }]]),
      label: _ctx.label,
      variant: _ctx.variant,
      placeholder: _ctx.placeholder,
      density: _ctx.density,
      readonly: _ctx.readonly,
      disabled: _ctx.disabled,
      type: _ctx.passwordType,
      value: _ctx.localTextVal,
      onKeyup: [
        _ctx.handleInput,
        _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"]))
      ],
      "hide-details": "auto",
      "append-inner-icon": _ctx.appendIconVal,
      "prepend-inner-icon": _ctx.prependInnerIcon,
      "onClick:appendInner": _ctx.clickAppendInnerIcon
    }, _createSlots({ _: 2 }, [
      (_ctx.isPrependInnerSlot)
        ? {
            name: "prepend-inner",
            fn: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.prependText), 1)
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.isAppendInnerSlot)
        ? {
            name: "append-inner",
            fn: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.appendText), 1)
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["modelValue", "class", "label", "variant", "placeholder", "density", "readonly", "disabled", "type", "value", "onKeyup", "append-inner-icon", "prepend-inner-icon", "onClick:appendInner"])
  ], 2))
}