<template>
  <div class="jodit-editor">
    <textarea :id="`editor${randomNum}`" @change="editorChange" />
  </div>
</template>

<script>
import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';

export default {
  name: 'CommonTextEditor',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    editorHeight: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      randomNum: Math.floor(Math.random() * 10000) + 1,
      editor: null,
      config: {
        language: 'en',
        toolbarButtonSize: 'small',
        height: 300,
        minHeight: 300,
        maxHeight: 300,
        askBeforePasteHTML: false,
        placeholder: '',
        buttons:
          'bold,strikethrough,underline,italic,|,left,center,right,indent,|,hr,font,fontsize,brush,paragraph,|,image,table,link,|,source,print,---,undo,redo',
      },
    };
  },
  mounted() {
    if (!this.editor) {
      this.setEditorHeight();
      this.editor = Jodit.make(`#editor${this.randomNum}`, this.config);
    }
    this.editor.value = this.modelValue;
  },
  watch: {
    modelValue: {
      handler(val) {
        this.editor.value = val;
      },
    },
  },
  beforeUnmount() {
    this.editor.destruct();
  },
  methods: {
    setEditorHeight() {
      if (this.editorHeight !== 300 && this.editorHeight > 0) {
        this.config.height = this.editorHeight;
        this.config.minHeight = this.editorHeight;
        this.config.maxHeight = this.editorHeight;
      }
    },
    editorChange() {
      this.$emit('update:modelValue', this.editor.value);
    },
  },
};
</script>
<style scoped>
.jodit-editor:deep(.jodit-status-bar),
.jodit-editor:deep(.jodit-add-new-line.jodit-add-new-line_after) {
  display: none;
}
.jodit-editor:deep(.jodit-editor__resize) {
  display: none;
  opacity: 0;
}
</style>
