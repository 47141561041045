<template>
  <router-view />
  <CommonCircleProgress :isLoading="isLoading" />
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import { CommonCircleProgress } from '@packages/components';
import { useProgressStore } from '@/stores/progress-store';
import '@/assets/css/global.scss';
export default defineComponent({
  name: 'App',
  components: {
    CommonCircleProgress,
  },
  setup() {
    const progressStore = useProgressStore();
    const isLoading = computed(() => {
      return progressStore.isGlobalProgress;
    });
    return { isLoading };
  },
});
</script>
