import { RouteRecordRaw } from 'vue-router';
import MRLayout from '@/layouts/MRLayout.vue';

const mrpath: Array<RouteRecordRaw> = [
  {
    path: '/mr',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'DashBoard',
        component: () => import('@/views/mr/MRDashBoard.vue'),
        meta: {
          menu: 'dashboard',
          category: 'dashboard',
          label: 'Main',
        },
      },
    ],
  },
  {
    path: '/mr/ers/tempdoc',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'tempDocList',
        component: () => import('@/views/mr/ers/ErsDocListPage.vue'),
        meta: {
          menu: 'ers',
          category: 'docs',
          docSta: 'CC.014.10',
        },
      },
    ],
  },
  {
    path: '/mr/ers/delegate',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'apprDelegate',
        component: () => import('@/views/mr/delegate/ApprDelegateListPage.vue'),
        meta: {
          menu: 'ers',
          category: 'delegate',
        },
      },
      {
        path: 'mng',
        name: 'ApprDelegateMngPage',
        component: () => import('@/views/mr/delegate/ApprDelegateMngPage.vue'),
        meta: {
          menu: 'ers',
          category: 'delegate',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/seminar',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'SeminarListPage',
        component: () => import('@/views/mr/smnr/SeminarListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'smnr',
          label: '제품설명회(단일)',
          type: 'SMNR',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/seminar/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'SeminarDetailPage',
        component: () => import('@/views/mr/smnr/SeminarDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'smnr',
          label: '제품설명회(단일)',
          type: 'SMNR',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/seminar-plural',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'SeminarListPluralPage',
        component: () => import('@/views/mr/smnr/SeminarListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'smnr',
          label: '제품설명회(복수)',
          type: 'SMNR_PLURAL',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/seminar-plural/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'SeminarPluralDetailPage',
        component: () => import('@/views/mr/smnr/SeminarDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'smnr',
          label: '제품설명회(복수)',
          type: 'SMNR_PLURAL',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/conference',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ConferenceListPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'conference',
          label: '학술대회 지원',
          type: 'SYMPO',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/conference/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ConferenceDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'conference',
          label: '학술대회 지원',
          type: 'SYMPO',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/clinic',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ClinicListPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'clinic',
          label: '임상시험 지원',
          type: 'CLINIC',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/clinic/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ClinicDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'clinic',
          label: '임상시험 지원',
          type: 'CLINIC',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/prov',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'SampleListPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'prov',
          label: '견본품 제공',
          type: 'PROV',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/prov/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'SampleDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'prov',
          label: '견본품 제공',
          type: 'PROV',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/lect',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'LectListPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'lect',
          label: '강연',
          type: 'LECT',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/lect/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'LectDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'lect',
          label: '강연',
          type: 'LECT',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/advice',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'AdviceListPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'advice',
          label: '자문',
          type: 'ADVICE',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/advice/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'AdviceDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'advice',
          label: '자문',
          type: 'ADVICE',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/exhb-adv',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ExhbAdvListPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'exhb-adv',
          label: '전시/광고',
          type: 'ADV',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/exhb-adv/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ExhbAdvDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'exhb-adv',
          label: '전시/광고',
          type: 'ADV',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/dc',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'DcPage',
        component: () => import('@/views/mr/cpr/CprListPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'dc',
          label: '대금 결제 조건에 따른 비용 할인',
          type: 'DC',
        },
      },
    ],
  },
  {
    path: '/mr/cpr/dc/:cprDocId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'DcDetailPage',
        component: () => import('@/views/mr/cpr/CprDetailPage.vue'),
        meta: {
          menu: 'cpr',
          category: 'dc',
          label: '대금 결제 조건에 따른 비용 할인',
          type: 'DC',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/cont',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ContListPage',
        component: () => import('@/views/mr/cont/ContListPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'cont',
          label: '고객정보',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/contDetail/:contId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ContDetailPage',
        props: true,
        component: () => import('@/views/mr/cont/ContDetailPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'cont',
          label: '고객정보상세',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/contUpdate/:contId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ContUpdatePage',
        props: true,
        component: () => import('@/views/mr/cont/ContUpdatePage.vue'),
        meta: {
          menu: 'mdm',
          category: 'cont',
          label: '고객정보수정',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/contNew',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ContNewPage',
        component: () => import('@/views/mr/cont/ContNewPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'contNew',
          label: '고객정보 신규등록',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/comp',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'CompListPage',
        component: () => import('@/views/mr/comp/CompListPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'comp',
          label: '거래처정보',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/compNew',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'CompNewPage',
        component: () => import('@/views/mr/comp/CompNewPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'comp',
          label: '거래처정보 신규등록',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/compDetail/:compId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'CompDetailPage',
        props: true,
        component: () => import('@/views/mr/comp/CompDetailPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'comp',
          label: '거래처정보 상세',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/compUpdate/:compId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'CompUpdatePage',
        props: true,
        component: () => import('@/views/mr/comp/CompUpdatePage.vue'),
        meta: {
          menu: 'mdm',
          category: 'comp',
          label: '거래처정보수정',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/contcomp',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ContCompStatePage',
        component: () => import('@/views/mr/contcomp/ContCompStatePage.vue'),
        meta: {
          menu: 'mdm',
          category: 'contcomp',
          label: '고객 / 거래처 등록 현황',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/contcompDetail/:id',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ContCompDetailPage',
        props: true,
        component: () => import('@/views/mr/contcomp/ContCompDetailPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'contcomp',
          label: '고객 / 거래처 등록 현황 상세',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/prod',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ProdListPage',
        component: () => import('@/views/mr/prod/ProdListPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'prod',
          label: '제품 정보',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/prod/:prodId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'ProdDetailPage',
        component: () => import('@/views/mr/prod/ProdDetailPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'prod',
          label: '제품 정보 상세',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/speaker',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'speaker',
        component: () => import('@/views/mr/speaker/SpeakerListPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'speaker',
          label: '연자 정보',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/speakerNew',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'speakerNew',
        component: () => import('@/views/mr/speaker/SpeakerNewPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'speaker',
          label: '연자 신규등록',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/speakerDetail/:speakerId/:type',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'speakerDetail',
        props: true,
        component: () => import('@/views/mr/speaker/SpeakerDetailPage.vue'),
        meta: {
          menu: 'mdm',
          category: 'speaker',
          label: '연자 정보 상세',
        },
      },
    ],
  },
  {
    path: '/mr/mdm/speakerRequestForChange/:speakerId/:type/:speakerData',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'speakerRequestForChange',
        props: true,
        component: () => import('@/views/mr/speaker/SpeakerRequestForChangePage.vue'),
        meta: {
          menu: 'mdm',
          category: 'speaker',
          label: '연자 정보 변경요청',
        },
      },
    ],
  },
  {
    path: '/mr/notice/:type(all|prod|cpr)',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'NoticePage',
        props: true,
        component: () => import('@/views/mr/NoticePage.vue'),
        meta: {
          menu: 'notice',
          category: 'notice',
        },
        beforeEnter: (to, from, next) => {
          switch (to.params.type) {
            case 'all':
              to.meta.label = '전체 ' + to.meta.label;
              break;
            case 'prod':
              to.meta.label = '제품 ' + to.meta.label;
              break;
            case 'cpr':
              to.meta.label = '지출보고 ' + to.meta.label;
              break;
            default:
              break;
          }
          next();
        },
      },
    ],
  },
  {
    path: '/mr/notice/:type(all|prod|cpr)/:noticeId',
    component: MRLayout,
    children: [
      {
        path: '',
        name: 'NoticeDetail',
        props: true,
        component: () => import('@/views/mr/NoticeDetailPage.vue'),
        meta: {
          menu: 'notice',
          category: 'notice',
        },
      },
    ],
  },
];

export default mrpath;
