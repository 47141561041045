<template>
  <div>
    <div v-if="breadcrumb" class="breadcrumb-text">{{ breadcrumb }}</div>
    <div v-if="title" class="breadcrumb-title">{{ title }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommonBreadCrumb',
  props: {
    breadcrumb: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.breadcrumb-text {
  font-size: 0.8em;
  color: gray;
}
.breadcrumb-title {
  font-weight: bold;
  font-size: 1.1em;
}
</style>
