import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo__ubsales.png'


const _hoisted_1 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "cursor-pointer",
    width: _ctx.width
  }, [
    _createElementVNode("img", {
      class: "d-block",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickedLogo && _ctx.clickedLogo(...args))),
      src: _imports_0,
      alt: "logo",
      width: "94",
      height: "16"
    })
  ], 8, _hoisted_1))
}