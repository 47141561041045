import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d454f10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex-center" }
const _hoisted_2 = { class: "pc-menu" }
const _hoisted_3 = { class: "pc-panel pb-10 px-12 d-flex" }
const _hoisted_4 = { class: "panel-title mt-8 mb-2" }
const _hoisted_5 = { class: "small-grey-text mt-6 mb-1" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLogo = _resolveComponent("CommonLogo")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonLogo),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localMenuList, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.id
        }, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePCMenu && _ctx.togglePCMenu(...args)))
          }, _toDisplayString(item.label), 1)
        ]))
      }), 128))
    ]),
    _createVNode(_component_v_menu, {
      transition: "slide-y-transition",
      "close-on-content-click": false,
      modelValue: _ctx.pcMenuPanel,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pcMenuPanel) = $event)),
      "no-click-animation": "",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localMenuList, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "panel-column"
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(item.label), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subgroup, (subitem) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: subitem.title
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(subitem.title), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subitem.list, (menu) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: menu.id,
                        class: "menu-item"
                      }, [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.clickMenu(menu))
                        }, _toDisplayString(menu.label), 9, _hoisted_6),
                        (menu.link)
                          ? (_openBlock(), _createBlock(_component_v_chip, {
                              key: 0,
                              class: "ml-2",
                              size: "x-small",
                              color: "blue",
                              variant: "flat"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("1")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ]))
              }), 128))
            ]))
          }), 128))
        ])), [
          [_directive_click_outside, _ctx.clickOutside]
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}