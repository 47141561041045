import { defineStore } from 'pinia';
interface MyInfoType {
  userId: string | null; // * 사용자 ID
  empNo: string | null; // * 직원 번호
  empNm: string | null; // * 직원 이름
  mrSiteUseYn: string | null; // * MR 사이트 사용 여부
  adSiteUseYn: string | null; // * AD 사이트 사용 여부
  mainDept: {
    deptId: number | null; // * 부서 ID
    deptNm: string | null; // * 부서 이름
  };
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    clientId: '' as string,
    dominId: 'ksaBiz' as string,
    user: {
      userId: null as string | null,
      username: null as string | null,
    },
    aToken: null as string | null,
    myInfo: {
      userId: null,
      empNo: null,
      empNm: null,
      mrSiteUseYn: 'Y',
      adSiteUseYn: 'N',
      mainDept: {
        deptId: null,
        deptNm: null,
      },
    } as MyInfoType,
  }),
  getters: {
    isLoggedIn: state => {
      if (state.aToken !== null && state.user.userId !== null) return true;
      return false;
    },
  },
  actions: {
    setDomainId(dominId: string) {
      this.dominId = dominId;
    },
    setUserInfo({ userId, username, token }: { userId: string; username: string; token: string }) {
      this.user.userId = userId;
      this.user.username = username;
      this.aToken = token;
    },
    resetUserInfo() {
      this.user.userId = null;
      this.user.username = null;
      this.aToken = null;
      this.resetMyInfo();
    },
    setMyInfo(myInfo: MyInfoType) {
      this.myInfo = myInfo;
    },
    resetMyInfo() {
      this.myInfo = {
        userId: null,
        empNo: null,
        empNm: null,
        mrSiteUseYn: 'Y',
        adSiteUseYn: 'N',
        mainDept: {
          deptId: null,
          deptNm: null,
        },
      };
    },
  },
  persist: true,
});
