import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
const app = createApp(App);

// pinia (store)
import { createPinia } from 'pinia';
const pinia = createPinia();
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

// Vuetify
import vuetify from './plugins/vuetify';

app.use(router).use(vuetify).mount('#app');

// 전역 컴포넌트 등록
import { BaseSnackBar } from '@packages/components';
import { BaseAlertDialog } from '@packages/components';
import { BaseTextField } from '@packages/components';

app.component('BaseAlertDialog', BaseAlertDialog);
app.component('BaseSnackBar', BaseSnackBar);
app.component('BaseTextField', BaseTextField);
