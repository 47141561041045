// VUE_APP_BASE_URL is set in .env.local and .env.production
//suppressProgress : suppressProgress 속성을 모든 요청에서 false로 설정하여 progressStore.setProgress(true)가 기본적으로 호출되도록 하고, 특정 요청에서만 suppressProgress를 true로 설정하여 이를 비활성화

import axios, { AxiosRequestConfig } from 'axios';
import { useProgressStore } from '@/stores/progress-store';
import authService from './auth/authService';

const axiosConfig: AxiosRequestConfig = {
  // baseURL: 'https://192.168.245.97:3443', // dev일때 proxy의 target 을 사용
  //  Authorization: Bearer `${token}`
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosCall = axios.create(axiosConfig);

// 요청 전에 실행되는 인터셉터
axiosCall.interceptors.request.use(
  config => {
    // TODO : 여기에 원하는 로깅이나 처리를 추가.

    // auth
    const token = getToken();
    if (token) config.headers['Authorization'] = `Bearer ${token}`;

    // progress
    const progressStore = useProgressStore();
    if (config.suppressProgress !== true) {
      progressStore.setProgress(true);
    }
    return config;
  },
  error => {
    // TODO : 요청이 실패했을 때의 처리를 추가.
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);

// 응답을 받았을 때 실행되는 인터셉터
axiosCall.interceptors.response.use(
  response => {
    // TODO: 여기에 원하는 로깅이나 처리를 추가.
    console.log('응답>>>>:', response);
    const progressStore = useProgressStore();
    if (response.config.suppressProgress !== true) {
      progressStore.setProgress(false);
    }
    return response;
  },
  async error => {
    // 에러 핸들러 함수를 async로 변경
    // TODO: 응답이 실패했을 때의 처리를 추가.
    console.error('응답 실패>>>>', error);
    const { response: errorResponse } = error;

    // 441 : 토큰 만료
    // 443 : 유효하지 않은 토큰
    // 인증 에러 발생시 refresh token을 이용해서 access token을 재발급
    if (errorResponse && errorResponse.status === 441) {
      console.log('토큰 만료되어 토큰 갱신을 시도합니다. >>>> errorResponse config:', errorResponse?.config?.url);
      return await resetTokenAndReattemptRequest(error);
    }

    const progressStore = useProgressStore();
    console.log('progressStore', progressStore.isGlobalProgress);
    // tiemout이라면 progress를 false로 변경
    if (error.code === 'ECONNABORTED') progressStore.setProgress(false);

    if (errorResponse.config.suppressProgress !== true) {
      progressStore.setProgress(false);
    }
    return Promise.reject(error);
  }
);
const getToken = () => {
  try {
    const authStorage = localStorage.getItem('auth') || '';
    const parsedJson = JSON.parse(authStorage);
    const accessToken = parsedJson.aToken || null;
    return accessToken;
  } catch (error) {
    console.log(error);
    return null;
  }
};

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

async function resetTokenAndReattemptRequest(error: any) {
  try {
    console.log('리프레시 토큰으로 어세스 토큰을 갱신 합니다 >>>');
    const { response: errorResponse } = error;

    // retryOriginalRequest는 pending 상태로 있다 access token을 받은 이후 onAccessTokenFetched가 호출될 때
    // access token을 넘겨 다시 axios로 요청하고 결과값을 처음 요청했던 promise의 resolve로 settle
    const retryOriginalRequest = new Promise((resolve, reject) => {
      addSubscriber(async (accessToken: any) => {
        try {
          errorResponse.config.headers['Authorization'] = 'Bearer ' + accessToken;
          resolve(axiosCall(errorResponse.config));
        } catch (err) {
          reject(err);
        }
      });
    });

    // refresh token을 이용해서 access token 요청
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true; // 문닫기 (한 번만 요청)

      const refreshResult = await authService.doRefreshToken();
      if (refreshResult.status !== 200) {
        throw new error('refresh token error');
      }

      isAlreadyFetchingAccessToken = false; // 문열기 (초기화)

      const authStorage = localStorage.getItem('auth') || '';
      const parsedJson = JSON.parse(authStorage);
      console.log(parsedJson);
      console.log('갱신에 성공했습니다.', parsedJson);

      onAccessTokenFetched(refreshResult.token);
    }

    return retryOriginalRequest; // pending 됐다가 onAccessTokenFetched가 호출될 때 resolve
  } catch (error) {
    console.log('resetToken fail', error);
    authService.doLogout();
    window.location.href = '/login?error=token_expired';
    return Promise.reject(error);
  }
}

// 대기열에 재시도가 필요한 요청을 추가
function addSubscriber(callback: any) {
  subscribers.push(callback);
}

// 새로운 인증 토큰을 받았을 때 대기열에 있는 요청들을 다시 실행
function onAccessTokenFetched(accessToken: any) {
  subscribers.forEach(callback => callback(accessToken));
  subscribers = [];
}

export default axiosCall;
