import { RouteRecordRaw } from 'vue-router';
import ADLayout from '@/layouts/ADLayout.vue';

const adpath: Array<RouteRecordRaw> = [
  {
    path: '/ad',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ADDashBoard',
        component: () => import('@/views/ad/ADDashBoard.vue'),
        meta: {
          menu: 'dashboard',
          category: 'dashboard',
          label: 'Main',
        },
      },
    ],
  },
  {
    path: '/ad/docs/compset',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ExpenseItemMngPage',
        component: () => import('@/views/ad/ExpenseItemMngPage.vue'),
        meta: {
          menu: 'report',
          category: 'docs',
          label: '지출보고 작성 항목 관리',
        },
      },
    ],
  },
  {
    path: '/ad/docs/state',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'DocsStatePage',
        component: () => import('@/views/ad/DocsStatePage.vue'),
        meta: {
          menu: 'report',
          category: 'docs',
          label: '문서 확인 및 상태 관리',
        },
      },
    ],
  },
  {
    path: '/ad/docs/speaker',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'DocsSpeakerPage',
        component: () => import('@/views/ad/DocsSpeakerPage.vue'),
        meta: {
          menu: 'report',
          category: 'docs',
          label: '연자관리',
        },
      },
    ],
  },
  {
    path: '/ad/docs/clinic',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'DocsClinicPage',
        component: () => import('@/views/ad/DocsClinicPage.vue'),
        meta: {
          menu: 'report',
          category: 'docs',
          label: '임상 관리',
        },
      },
    ],
  },
  {
    path: '/ad/docs/appr',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ApprMngPage',
        component: () => import('@/views/ad/ApprMngPage.vue'),
        meta: {
          menu: 'report',
          category: 'docs',
          label: '결재선관리',
        },
      },
    ],
  },
  {
    path: '/ad/docs/holiday',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'AdHolidaySetPage',
        component: () => import('@/views/ad/AdHolidaySetPage.vue'),
        meta: {
          menu: 'report',
          category: 'docs',
          label: '휴일 관리',
        },
      },
    ],
  },
  {
    path: '/ad/result/critical',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ResultCriticalPage',
        component: () => import('@/views/ad/ResultCriticalPage.vue'),
        meta: {
          menu: 'report',
          category: 'result',
          label: '임계값 관리',
        },
      },
    ],
  },
  {
    path: '/ad/result/cpmonitoring',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ResultCpMonitorPage',
        component: () => import('@/views/ad/ResultCpMonitorPage.vue'),
        meta: {
          menu: 'report',
          category: 'result',
          label: 'CP모니터링',
        },
      },
    ],
  },
  {
    path: '/ad/result/seminar',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ResultSmnrPage',
        component: () => import('@/views/ad/ResultSmnrPage.vue'),
        meta: {
          menu: 'report',
          category: 'result',
          label: '제품설명회 참석 관리',
        },
      },
    ],
  },
  {
    path: '/ad/result/verification',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ResultVerificationPage',
        component: () => import('@/views/ad/ResultVerificationPage.vue'),
        meta: {
          menu: 'report',
          category: 'result',
          label: '지출보고 내역 검증',
        },
      },
    ],
  },
  {
    path: '/ad/result/submission',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ResultSubmissionPage',
        component: () => import('@/views/ad/ResultSubmissionPage.vue'),
        meta: {
          menu: 'report',
          category: 'result',
          label: '지출보고서 제출',
        },
      },
    ],
  },
  {
    path: '/ad/analyze/summary',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'AnalyzeSummaryPage',
        component: () => import('@/views/ad/AnalyzeSummaryPage.vue'),
        meta: {
          menu: 'report',
          category: 'analyze',
          label: '통계 요약',
        },
      },
    ],
  },
  {
    path: '/ad/analyze/manager',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'AnalyzeMngPage',
        component: () => import('@/views/ad/AnalyzeMngPage.vue'),
        meta: {
          menu: 'report',
          category: 'analyze',
          label: '담당자별 문서 통계',
        },
      },
    ],
  },
  {
    path: '/ad/analyze/team',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'AnalyzeTeamPage',
        component: () => import('@/views/ad/AnalyzeTeamPage.vue'),
        meta: {
          menu: 'report',
          category: 'analyze',
          label: '팀별 문서 통계',
        },
      },
    ],
  },
  {
    path: '/ad/analyze/cont',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'AnalyzeContPage',
        component: () => import('@/views/ad/AnalyzeContPage.vue'),
        meta: {
          menu: 'report',
          category: 'analyze',
          label: '의료인별 문서 통계',
        },
      },
    ],
  },
  {
    path: '/ad/info/cont',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ContInfoPage',
        component: () => import('@/views/ad/ContInfoPage.vue'),
        meta: {
          menu: 'info',
          category: 'cont',
          label: '고객 정보',
        },
      },
    ],
  },
  {
    path: '/ad/info/comp',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'CompInfoPage',
        component: () => import('@/views/ad/CompInfoPage.vue'),
        meta: {
          menu: 'info',
          category: 'cont',
          label: '거래처 정보',
        },
      },
    ],
  },
  {
    path: '/ad/info/offer',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'OfferAgrStatePage',
        component: () => import('@/views/ad/OfferAgrStatePage.vue'),
        meta: {
          menu: 'info',
          category: 'cont',
          label: '개인정보 동의 현황',
        },
      },
    ],
  },
  {
    path: '/ad/info/term',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'OfferAgrTermPage',
        component: () => import('@/views/ad/OfferAgrTermPage.vue'),
        meta: {
          menu: 'info',
          category: 'cont',
          label: '개인정보 동의 약관',
        },
      },
    ],
  },
  {
    path: '/ad/info/handover',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'HandoverPage',
        component: () => import('@/views/ad/HandOverPage.vue'),
        meta: {
          menu: 'info',
          category: 'cont',
          label: '담당자 인수 인계',
        },
      },
    ],
  },
  {
    path: '/ad/info/editreq',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'EditReqPage',
        component: () => import('@/views/ad/EditReqPage.vue'),
        meta: {
          menu: 'info',
          category: 'cont',
          label: '신규/변경 요청',
        },
      },
    ],
  },
  {
    path: '/ad/info/product',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ProdInfoPage',
        component: () => import('@/views/ad/ProdInfoPage.vue'),
        meta: {
          menu: 'info',
          category: 'prod',
          label: '제품 정보',
        },
      },
    ],
  },
  {
    path: '/ad/info/prod/resource',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ProdResourcePage',
        component: () => import('@/views/ad/ProdResourcePage.vue'),
        meta: {
          menu: 'info',
          category: 'prod',
          label: '제품 자료',
        },
      },
    ],
  },
  {
    path: '/ad/info/dept',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'DeptMngPage',
        component: () => import('@/views/ad/DeptMngPage.vue'),
        meta: {
          menu: 'info',
          category: 'user',
          label: '부서 관리',
        },
      },
    ],
  },
  {
    path: '/ad/info/employee',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'DeptEmpPage',
        component: () => import('@/views/ad/DeptEmpPage.vue'),
        meta: {
          menu: 'info',
          category: 'user',
          label: '부서원 관리',
        },
      },
    ],
  },
  {
    path: '/ad/info/usergroup',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'userGroupPage',
        component: () => import('@/views/ad/UserGroupPage.vue'),
        meta: {
          menu: 'info',
          category: 'user',
          label: '사용자 그룹 관리',
        },
      },
    ],
  },
  {
    path: '/ad/example',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ExamplePage',
        component: () => import('@/views/ExamplePage.vue'),
        meta: {
          menu: '',
          category: 'example',
          label: '샘플 페이지',
        },
      },
    ],
  },
  {
    path: '/ad/notice/:type(all|prod|cpr)',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'NoticeSettingPage',
        component: () => import('@/views/ad/NoticeSettingPage.vue'),
        meta: {
          menu: 'setting',
          category: 'notice',
          label: '공지사항',
        },
        props: true,
        beforeEnter: (to, from, next) => {
          switch (to.params.type) {
            case 'all':
              to.meta.label = '전체 ' + to.meta.label;
              break;
            case 'prod':
              to.meta.label = '제품 ' + to.meta.label;
              break;
            case 'cpr':
              to.meta.label = '지출보고 ' + to.meta.label;
              break;
            default:
              break;
          }
          next();
        },
      },
    ],
  },
  {
    path: '/ad/config/role',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'RoleConfigPage',
        component: () => import('@/views/ad/RoleConfigPage.vue'),
        meta: {
          menu: 'setting',
          category: 'config',
          label: '권한설정',
        },
      },
    ],
  },
  {
    path: '/ad/config/admin',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'AdminConfigPage',
        component: () => import('@/views/ad/AdminConfigPage.vue'),
        meta: {
          menu: 'setting',
          category: 'config',
          label: '관리자설정',
        },
      },
    ],
  },
  {
    path: '/ad/config/code',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'ConfigCodePage',
        component: () => import('@/views/ad/ConfigCodePage.vue'),
        meta: {
          menu: 'setting',
          category: 'config',
          label: '코드 관리',
        },
      },
    ],
  },
  {
    path: '/ad/config/opt',
    component: ADLayout,
    children: [
      {
        path: '',
        name: 'OptConfigPage',
        component: () => import('@/views/ad/OptConfigPage.vue'),
        meta: {
          menu: 'setting',
          category: 'config',
          label: '옵션 관리',
        },
      },
    ],
  },
];

export default adpath;
