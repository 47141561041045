import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ff8878e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-date-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["custom-field-title", { required: _ctx.isRequired }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex", _ctx.size])
    }, [
      _createVNode(_component_v_menu, {
        modelValue: _ctx.isFromDatePicker,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isFromDatePicker) = $event)),
        transition: "slide-y-transition",
        "close-on-content-click": false
      }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_text_field, _mergeProps(props, {
            modelValue: _ctx.currentFromVal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentFromVal) = $event)),
            variant: _ctx.variant,
            density: "compact",
            disabled: _ctx.disabled,
            "hide-details": "auto",
            placeholder: "YYYY-MM-DD",
            class: "mr-1"
          }), null, 16, ["modelValue", "variant", "disabled"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "picker-wrap" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_date_picker, {
                modelValue: _ctx.localFromVal,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFromVal) = $event)),
                  _ctx.changeFromDate
                ],
                "hide-header": "",
                min: _ctx.min,
                max: _ctx.max
              }, null, 8, ["modelValue", "min", "max", "onUpdate:modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_menu, {
        modelValue: _ctx.isToDatePicker,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isToDatePicker) = $event)),
        transition: "slide-y-transition",
        "close-on-content-click": false
      }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_text_field, _mergeProps(props, {
            modelValue: _ctx.currentToVal,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentToVal) = $event)),
            variant: _ctx.variant,
            density: "compact",
            disabled: _ctx.disabled,
            "hide-details": "auto",
            placeholder: "YYYY-MM-DD"
          }), null, 16, ["modelValue", "variant", "disabled"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "picker-wrap" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_date_picker, {
                modelValue: _ctx.localToVal,
                "onUpdate:modelValue": [
                  _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localToVal) = $event)),
                  _ctx.changeToDate
                ],
                "hide-header": "",
                min: _ctx.localFromVal,
                max: _ctx.max
              }, null, 8, ["modelValue", "min", "max", "onUpdate:modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 2)
  ]))
}