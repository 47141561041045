import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contents-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.localData.isOpen,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localData.isOpen) = $event)),
      _ctx.closeSnackbar
    ],
    timeout: _ctx.localData.timeOut
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.localData.text), 1)
    ]),
    _: 2
  }, [
    (_ctx.localData.timeOut === -1)
      ? {
          name: "actions",
          fn: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "white",
              variant: "text",
              size: "small",
              onClick: _ctx.closeSnackbar
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 닫기 ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "timeout", "onUpdate:modelValue"]))
}