<template>
  <div class="pa-2 position-relative">
    <Vue3Signature
      ref="refSignature"
      :sigOption="state.option"
      :w="'340px'"
      :h="'300px'"
      :disabled="state.disabled"
      class="signpad"></Vue3Signature>
    <div class="watermark">서명을 해주세요.</div>
  </div>
  <div class="d-flex-center-between pa-2">
    <div>
      <v-btn variant="outlined" size="small" class="mr-1" @click="clear"> 초기화 </v-btn>
      <v-btn variant="outlined" size="small" class="mr-1" @click="undo"> 이전 </v-btn>
    </div>
    <v-btn size="small" color="green" @click="save('image/jpeg')"> 서명완료 </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import Vue3Signature from 'vue3-signature';
export default defineComponent({
  name: 'CommonSignOnly',
  components: { Vue3Signature },
  emits: ['save'],
  setup(props, { emit, expose }) {
    const state = reactive({
      count: 0,
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)',
      },
      disabled: false,
    });
    const refSignature = ref();
    const save = (t: any) => {
      emit('save', refSignature.value.save(t));
    };
    const clear = () => {
      refSignature.value.clear();
    };
    const undo = () => {
      refSignature.value.undo();
    };
    expose({ clear });
    return {
      state,
      refSignature,
      save,
      clear,
      undo,
    };
  },
});
</script>

<style scoped>
.position-relative {
  position: relative;
}
.signpad {
  margin: 0 auto;
  border: 1px solid #13bd7e;
  position: relative;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-family: Arial, sans-serif;
  color: #13bd7e;
  pointer-events: none; /* 클릭 등의 이벤트를 무시합니다. */
  opacity: 0.7;
  transition: opacity 0.3s;
}
</style>
