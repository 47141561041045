<template>
  <div class="global-nav-wrap">
    <div class="px-4 py-3">
      <div class="px-md-4 px-1 pb-8">
        <div class="text-right">
          <v-btn variant="text" icon="mdi-close" elevation="0" @click="clickClose" />
        </div>
        <div v-for="(item, index) in localMenuList" :key="index">
          <div class="font-bold d-flex-align-center w100p mb-9" :class="{ 'mt-9': index !== 0 }">
            <div style="flex-shrink: 0">{{ item.label }}</div>
            <div style="flex-grow: 1" class="pl-3"><hr /></div>
          </div>
          <v-row v-for="subitem in item.subgroup" :key="subitem.title">
            <v-col
              cols="4"
              sm="3"
              v-for="(menu, menuIdx) in subitem.list"
              :key="menu.id"
              class="menu-item px-2"
              @click="clickMenu(menu)">
              <div class="menu-icon">
                <v-chip v-if="menuIdx === 3" class="new" size="x-small" color="blue" variant="flat">1</v-chip>
              </div>
              <div class="mt-2 menu-name">{{ menu.mlabel }}</div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { MenuItemType, SubGroupType } from '@/types/interface/menu';
import { computed } from 'vue';

export default defineComponent({
  props: {
    menuList: {
      type: Array as () => MenuItemType[],
      default: () => [],
    },
  },
  setup(props, context) {
    let findSubgroup = ref<SubGroupType[]>([]);

    const localMenuList = computed(() => {
      return props.menuList;
    });

    const clickMenu = (item: MenuItemType) => {
      context.emit('clickMenu', item);
    };
    let openedGroups = ref([]);

    const clickClose = () => {
      context.emit('clickClose');
    };

    return {
      localMenuList,
      openedGroups,
      findSubgroup,
      clickClose,
      clickMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.global-nav-wrap {
  min-width: 100%;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.mo-logo {
  width: 110px;
  padding: 0px 10px;
  &:hover {
    cursor: pointer;
  }
}
.menu-item {
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  .menu-icon {
    position: relative;
    width: 56px;
    height: 56px;
    border: 1px solid #ebebeb;
    border-radius: 50%;
  }
  .new {
    position: absolute;
    top: 0;
    right: -8px;
  }
  .menu-name {
    word-break: keep-all;
  }
}
</style>
