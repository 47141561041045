<template>
  <div :class="{ 'fixed-footer': fixed }" class="footer-wrap">
    <div class="d-flex-center-between footer">
      <img v-show="isLogoImg" src="@/assets/images/footer__logo.png" alt="ubsales logo" width="82px" height="14px" />
      <span class="copyright"> UBcare Corp. All rights reserved.</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommonFooter',
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    isLogoImg: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.fixed-footer {
  position: absolute;
  bottom: 0px;
}
.footer {
  padding: 6px 24px;
}
.copyright {
  padding: 0px 6px;
  font-size: 12px;
  color: #666;
}
</style>
