<template>
  <div class="text-right-input" :class="wrapClass">
    <v-text-field
      v-model="formattedValue"
      class="custom-text-field"
      :class="[{ required: isRequired }]"
      :variant="variant"
      type="text"
      :placeholder="placeholder"
      :density="density"
      :readonly="readonly"
      :disabled="disabled"
      @keyup="handleInput"
      @keyup.enter="$emit('enter')"
      hide-details="auto">
      <template v-slot:prepend-inner v-if="isMinus">
        <div>-</div>
      </template>
      <template v-slot:append-inner>
        <div>{{ appendText }}</div>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from 'vue';

type VariantType = 'outlined' | 'filled' | 'plain' | 'solo' | 'solo-filled' | undefined;
type DensityType = 'default' | 'comfortable' | 'compact' | null | undefined;

export default defineComponent({
  name: 'BaseTextField',
  props: {
    textVal: { type: [String, Number], default: undefined },
    density: { type: String as () => DensityType, default: 'compact' },
    variant: {
      type: String as () => VariantType,
      default: 'outlined',
    },
    placeholder: { type: String, default: undefined },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    wrapClass: { type: String, default: '' },
    appendText: { type: String, default: '원' },
    isMinus: { type: Boolean, default: false },
  },
  emits: ['update:textVal', 'enter'],
  setup(props, context) {
    const formattedValue = ref<string | undefined>(formatCurrency(props.textVal));

    watch(
      () => props.textVal,
      newVal => {
        if (newVal === 0) {
          formattedValue.value = undefined;
        } else {
          formattedValue.value = formatCurrency(newVal);
        }
      },
      { immediate: true }
    );

    const handleInput = ($event: InputEvent) => {
      const inputElement = $event.target as HTMLInputElement;
      const value = inputElement.value.replace(/,/g, '');

      if (value === '') {
        context.emit('update:textVal', 0);
        formattedValue.value = undefined;
        return;
      }

      const tempVal = value.replace(/[^0-9]/g, '');
      context.emit('update:textVal', parseInt(tempVal));
      formattedValue.value = formatCurrency(tempVal);
    };

    function formatCurrency(value: number | string | undefined): string {
      if (value === undefined || value === null || value === '') {
        return '';
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return {
      formattedValue,
      handleInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-text-field {
  min-width: 60px;
}
</style>
