<template>
  <v-navigation-drawer :width="200" class="pa-0 pt-2 ad-drawer" v-model="isDrawer" :mobile-breakpoint="0" permanent>
    <template v-if="menuList && menuList.length > 0">
      <v-list-item v-for="menu in menuList" :key="menu.id" class="text-left">
        <div class="ad-drawer-title mb-1" :class="{ active: currentRouteInfo.cat === menu.id }">
          <v-icon size="small" class="mr-1">{{ menu.icon }}</v-icon>
          {{ menu.text }}
        </div>
        <ul v-if="menu.child && menu.child.length > 0" class="pa-1 ad-drawer-submenu">
          <li
            v-for="(submenu, idx) in menu.child"
            :key="idx"
            class="cursor-pointer"
            :class="{ 'txt-menuactive': submenu.link === currentRouteInfo.path }"
            @click="moveADPage(submenu)">
            {{ submenu.text }}
          </li>
        </ul>
      </v-list-item></template
    >
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import admenu from '@/assets/json/admenu.json';
interface SubMenuType {
  text: string;
  link: string;
}
type AdMenuType = {
  [key: string]: {
    id: string;
    text: string;
    icon: string;
    child: SubMenuType[];
  }[];
};
export default defineComponent({
  setup() {
    const $router = useRouter();
    const isDrawer = computed(() => {
      if ($router.currentRoute.value.name === 'ADDashBoard') {
        return false;
      }
      return true;
    });

    const menuList = computed(() => {
      const admenuList: AdMenuType = { ...admenu };
      let textLabel = String($router.currentRoute.value?.meta?.menu || '');
      if (textLabel) return admenuList[textLabel];
      return [];
    });

    const moveADPage = (submenu: SubMenuType) => {
      if (submenu.link) {
        $router.push(submenu.link);
      } else {
        alert('준비중입니다.');
      }
    };

    const currentRouteInfo = computed(() => {
      return {
        cat: $router.currentRoute.value.meta.category,
        path: $router.currentRoute.value.path,
      };
    });
    return { isDrawer, menuList, moveADPage, currentRouteInfo };
  },
});
</script>

<style lang="scss" scoped>
.ad-drawer {
  background: #f9f9fb;
  .ad-drawer-title {
    padding: 4px 8px;
    border-radius: 8px;
    &.active {
      background-color: #f0eafa;
    }
  }
  .ad-drawer-submenu {
    li {
      padding: 4px 0px;
    }
  }
  .txt-menuactive {
    color: #5c3efd;
  }
}
</style>
