<template>
  <div class="common-date-picker">
    <span v-if="title" class="custom-field-title" :class="{ required: isRequired }">{{ title }}</span>
    <v-menu v-model="isTimePicker" transition="slide-y-transition" :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <v-text-field
          v-bind="props"
          v-model="currentVal"
          :variant="variant"
          density="compact"
          :disabled="disabled"
          hide-details="auto"
          placeholder="YYYY-MM-DD"
          class="mr-1" />
      </template>
      <v-card class="picker-wrap">
        <v-date-picker
          v-model="localVal"
          hide-header
          :min="min"
          :max="max"
          @update:modelValue="changedDate"></v-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from 'vue';
import { useDate } from 'vuetify';
type VariantType = 'outlined' | 'filled' | 'plain' | 'solo' | 'solo-filled' | undefined;
export default defineComponent({
  name: 'CommonDatePicker',
  props: {
    dateVal: { type: String, default: undefined, required: true },
    title: { type: String, default: undefined },
    isRequired: { type: Boolean, default: false },
    min: { type: String, default: '1900-01-01' },
    max: { type: String, default: '2100-12-31' },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'fit' },
    variant: {
      type: String as () => VariantType,
      default: 'outlined',
    },
  },
  setup(props, context) {
    const adapter = useDate();

    // 부모로부터 받은 날짜 초기화
    const propsdateVal = props.dateVal || '';

    let currentVal = ref(propsdateVal);
    let localVal = ref(adapter.parseISO(propsdateVal));

    // datepicker 토글
    const isTimePicker = ref(false);

    watch(
      () => props.dateVal,
      (newVal, oldVal) => {
        if (!newVal) {
          currentVal.value = '';
          localVal.value = '';
        }
        if (newVal && newVal !== oldVal) {
          currentVal.value = newVal;
          localVal.value = adapter.parseISO(newVal);
        }
      }
    );

    // 날짜 변경
    const changedDate = () => {
      const tempVal = adapter.toISO(localVal.value);
      console.log('nan', tempVal.includes('NaN'), localVal.value);
      if (!tempVal.includes('NaN')) {
        currentVal.value = tempVal;
        context.emit('update:dateVal', currentVal.value);
        isTimePicker.value = false;
      }
    };

    return {
      isTimePicker,
      localVal,
      currentVal,
      changedDate,
    };
  },
});
</script>

<style scoped>
.fit {
  width: 220px;
}
.full {
  width: 100%;
}
.picker-wrap {
  width: 260px;
}
</style>
