import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pa-4" }
const _hoisted_2 = {
  key: 0,
  class: "pb-2"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
    "max-width": "300",
    "onClick:outside": _ctx.clickOutside
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.parentData.title)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.parentData.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "small-text",
              innerHTML: _ctx.parentData.body
            }, null, 8, _hoisted_3)
          ]),
          _createVNode(_component_v_divider, { color: "primary" }),
          (_ctx.parentData.isConfirm)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_v_btn, _mergeProps(_ctx.mergeOpt({ color: null }), {
                  onClick: _ctx.cancel,
                  textContent: _toDisplayString(_ctx.parentData.cancelText ?? '취소')
                }), null, 16, ["onClick", "textContent"]),
                _createVNode(_component_v_btn, _mergeProps(Object.assign(_ctx.btnOpt), {
                  onClick: _ctx.submit,
                  textContent: _toDisplayString(_ctx.parentData.submitText ?? '확인')
                }), null, 16, ["onClick", "textContent"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_v_btn, _mergeProps(_ctx.mergeOpt({ class: 'w100p' }), {
                  onClick: _ctx.submit,
                  textContent: _toDisplayString(_ctx.parentData.submitText ?? '확인')
                }), null, 16, ["onClick", "textContent"])
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onClick:outside"]))
}