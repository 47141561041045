import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5369e546"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "breadcrumb-text"
}
const _hoisted_2 = {
  key: 1,
  class: "breadcrumb-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.breadcrumb)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.breadcrumb), 1))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true)
  ]))
}