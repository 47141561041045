<template>
  <div>
    <v-text-field
      :model-value="props.modelValue"
      @update:model-value="updateModelValue"
      @input="handleInput"
      density="compact"
      :variant="props.variant"
      hide-details
      placeholder="YYYY-MM"></v-text-field>
  </div>
</template>

<script setup lang="ts">
// Prop 정의 및 Emits 함수 설정
type VariantType = 'outlined' | 'filled' | 'plain' | 'solo' | 'solo-filled' | undefined;

const props = withDefaults(
  defineProps<{
    modelValue: string;
    variant?: VariantType;
  }>(),
  {
    variant: 'solo-filled',
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;
  let value = target.value;
  value = value.replace(/\D/g, ''); // Remove non-digits
  if (value.length <= 4) {
    emit('update:modelValue', value);
  } else if (value.length <= 6) {
    emit('update:modelValue', `${value.slice(0, 4)}-${value.slice(4, 6)}`);
  } else if (value.length > 6) {
    emit('update:modelValue', `${value.slice(0, 4)}-${value.slice(4, 6)}`);
  }
}

function updateModelValue(value: string) {
  emit('update:modelValue', value);
}
</script>
