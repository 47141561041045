import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VCalendar } from 'vuetify/labs/VCalendar';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '@/assets/css/override-vuetify.scss';

export default createVuetify({
  components: {
    ...components,
    VCalendar,
  },
  directives,
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#13BD7E',
          ubsblue: '#3F94E8',
          ubsIndigo: '#000929',
          ubsred: '#F14E32',
          ubsroyal: '#7250C7',
        },
      },
    },
  },
});
