<template>
  <div class="ubs-account">
    <v-menu transition="slide-x-transition" right>
      <template v-slot:activator="{ props, isActive }">
        <div class="d-flex-center cursor-pointer" v-bind="props">
          <v-avatar color="ubsblue" size="x-small" class="mr-2" v-bind="props">
            <strong>
              <small>{{ userName ? userName[0].toUpperCase() : 'U' }}</small>
            </strong>
          </v-avatar>
          <div class="hide-sm-and-down account-name mr-2">{{ userDept }} {{ userName }}</div>
          <v-icon
            :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            size="20"
            class="pa-0 ma-0 hide-sm-and-down"></v-icon>
        </div>
      </template>
      <v-card class="pa-2 mt-1 ml-sm-6 ml-0" width="130">
        <ul class="account-menu">
          <li @click="goSetting">내 정보 변경</li>
          <li @click="goPwReset">비밀번호 변경</li>
          <li @click="tryLogout">로그아웃</li>
        </ul>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/auth/authService';
import { useAuthStore } from '@/stores/auth-store';

interface PropsType {
  location?: string;
}

const props = withDefaults(defineProps<PropsType>(), {
  location: 'mr',
});

const userStore = useAuthStore();
const router = useRouter();

const userName = computed(() => {
  return userStore.myInfo.empNm;
});
const userDept = computed(() => {
  return userStore.myInfo?.mainDept.deptNm;
});

const tryLogout = async () => {
  try {
    await AuthService.doLogout();
  } catch (error) {
    console.log('logoutResult fail');
  } finally {
    props.location === 'ad' ? router.push('/ad/login') : router.push('/login');
  }
};

const goPwReset = async () => {
  router.push('/pwdresetreq');
};
const goSetting = async () => {
  router.push('/modinfo');
};
</script>
<style lang="scss" scoped>
.account-name {
  font-size: 13px;
}
.account-menu {
  padding: 8px 0px;
  align-items: flex-end;
  li {
    font-size: 13px;
    font-weight: 600;
    padding: 10px 16px;
    color: #666;
  }
  li:hover {
    color: #111;
    cursor: pointer;
  }
}
</style>
