import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6afb1ea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex-center app-bar-right" }
const _hoisted_2 = { class: "d-flex-center-between w100p width-limit" }
const _hoisted_3 = { class: "width-limit" }
const _hoisted_4 = { class: "mt-4 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheGlobalNavList = _resolveComponent("TheGlobalNavList")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_CommonLogo = _resolveComponent("CommonLogo")!
  const _component_TheAccount = _resolveComponent("TheAccount")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_ThePCNavPanel = _resolveComponent("ThePCNavPanel")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CommonFooter = _resolveComponent("CommonFooter")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          (_ctx.$vuetify.display.mdAndDown)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.globalNavDrawer)
                  ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
                      key: 0,
                      width: "100%",
                      class: "pa-0 ma-0",
                      location: "right"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TheGlobalNavList, {
                          menuList: _ctx.menuList,
                          onClickMenu: _ctx.changeMenu,
                          onClickClose: _ctx.toggleGlobalNav
                        }, null, 8, ["menuList", "onClickMenu", "onClickClose"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_app_bar, {
                  elevation: "0",
                  class: "gloabl-app-bar pl-6 pr-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CommonLogo),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_TheAccount),
                      _createVNode(_component_v_btn, {
                        onClick: _ctx.toggleGlobalNav,
                        icon: "mdi-menu",
                        variant: "flat",
                        density: "compact"
                      }, null, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ], 64))
            : (_openBlock(), _createBlock(_component_v_app_bar, {
                key: 1,
                elevation: "0",
                class: "gloabl-app-bar px-8"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ThePCNavPanel, {
                      menuList: _ctx.menuList,
                      onClickMenu: _ctx.changeMenu
                    }, null, 8, ["menuList", "onClickMenu"]),
                    _createVNode(_component_TheAccount)
                  ])
                ]),
                _: 1
              })),
          _createVNode(_component_v_main, { class: "overflow-y-auto mr-main" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_view)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_CommonFooter, { fixed: false })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}