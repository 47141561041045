<template>
  <v-dialog persistent overlay-color="white" v-model="isShowCircle">
    <div class="text-center">
      <v-progress-circular indeterminate color="primary" class="ma-auto"></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'CommonCircleProgress',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isShowCircle = computed(() => {
      return props.isLoading;
    });
    return {
      isShowCircle,
    };
  },
});
</script>
